import { atom } from "jotai";
import {
    Navigate,
    Outlet,
    RouterProvider,
    createBrowserRouter,
    redirect
} from "react-router-dom";
import MedicalTravel from "./routes/MedicalTravel";
import AddMore from "./routes/AddMore";
import ByBoth from "./routes/ByBoth";
import ByPrivate from "./routes/ByPrivate";
import ByPublic from "./routes/ByPublic";
import ContactDetails from "./routes/ContactDetails";
import OtherUploads from "./routes/OtherUploads";
import TravelDetails from "./routes/TravelDetails";
import Summary from "./routes/Summary";
import ThankYou from "./routes/ThankYou";
import Fail from "./routes/SendFailed";
import { MedicalTravelModel } from "./types/FormTypes";
import Root from "./routes/root";
import Translate from "./routes/Translate";
import AddCertificate from "./routes/AddCertificate";
require("./extensions/yup-extensions");

/*
    Store - Collecting FormData
*/
export const formDataAtom = atom<MedicalTravelModel>({
    compensationfor: "",
    patientssn: "",
    patientfirstname: "",
    patientlastname: "",
    patientemail: "",
    patientphone: "",
    caregiverssn: "",
    caregiverfirstname: "",
    caregiverphone: "",
    caregiverlastname: "",
    caregiveremail: "",
    medicalVisits: [],
});

const state = { followingForm: false };
const routerBasename = (window as any).OGT?.WebApp?.routerBasename ?? "";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                index: true,
                element: <MedicalTravel />,
                loader: () => {
                    state.followingForm = true;
                    return null;
                }
            },
            {
                path: "kontaktuppgifter",
                element: <ContactDetails />,
                loader: async () => {
                    return state.followingForm ? null : redirect("/");
                },
            },
            {
                path: "reseuppgifter/:id?",
                element: <Outlet />,
                loader: async () => {
                    return state.followingForm ? null : redirect("/");
                },
                children: [
                    {
                        index: true,
                        element: <Navigate to="/reseuppgifter/intyg" replace={true} />,
                    },
                    {
                        path: "intyg",
                        element: <AddCertificate />,
                    },
                    {
                        path: "om-resan",
                        element: <TravelDetails />,
                    },
                    {
                        path: "bil",
                        element: <ByPrivate />,
                    },
                    {
                        path: "kollektivtrafik",
                        element: <ByPublic />,
                    },
                    {
                        path: "bil-och-kollektivtrafik",
                        element: <ByBoth />,
                    },
                    {
                        path: "kostnader-for-overnattning",
                        element: <OtherUploads />,
                    },
                    {
                        path: "fler-resor",
                        element: <AddMore />,
                    },
                ],
            },
            {
                path: "granska-och-skicka",
                element: <Summary />,
                loader: async () => {
                    return state.followingForm ? null : redirect("/");
                }
            },
            {
                path: "tack-sida",
                element: <ThankYou />,
                loader: async () => {
                    return state.followingForm ? null : redirect("/");
                },
            },
            {
                path: "ett-fel-intraffade",
                element: <Fail />,
                loader: async () => {
                    return state.followingForm ? null : redirect("/");
                },
            },
            {
                path: "oversatt",
                element: <Translate />,
            }
        ]
    }
],
    {
        basename: routerBasename
    }
);

// Count pages WIP
const pagePaths = router?.routes[0]?.children?.map((child) => child.path).filter(Boolean) || [];

export const pageCountAtom = atom({
  pagePaths,
});

function App() {
    // const [formData] = useAtom(formDataAtom);
    // const [medicalVisit] = useAtom(medicalVisitAtom);
    return (
        <>
            <RouterProvider router={router} />
            {
                //<div className="bg-slate-900 text-white p-6 text-xs  grid-cols-2 gap-10 hidden md:grid">
                //    <pre>{JSON.stringify(formData, null, 2)}</pre>
                //    <pre>{JSON.stringify(medicalVisit, null, 2)}</pre>
                //</div> 
            }
        </>
    );
}

export default App;
